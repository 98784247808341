import { Button } from "antd";
import styles from "./style.module.scss";

export default function Custom404() {
  return (
    <div className={styles.container}>
      <div className={styles.description}>404 内容不存在</div>
      <Button type="link" href="/">前往首页</Button>
    </div>
  );
}
